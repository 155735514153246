import { useLinkProtocols } from '/machinery/useLinkProtocols'

import styles from './Link.css'

export function Link({ href, dataX, label, layoutClassName = undefined }) {
  const { rel, target } = useLinkProtocols({ href })

  return (
    <a data-x={dataX} className={cx(styles.component, layoutClassName)} {...{ href, rel, target }} >
      {label}
    </a>
  )
}
