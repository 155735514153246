import { useMutation } from '@tanstack/react-query'
import { signInAnonymously, getAuth } from 'firebase/auth'
import { getDatabase, push, ref, serverTimestamp } from 'firebase/database'
import { useForm } from '@kaliber/forms'
import { required, optional, email } from '@kaliber/forms/validation'

import { useTranslate, useLanguage } from '/machinery/I18n'
import { useFirebaseApp } from '/machinery/FirebaseAppProvider'
import { useReportError } from '/machinery/ReportError'
import { useClientConfig } from '/machinery/ClientConfig'
import { storeHashedEmail } from '/machinery/tracking/storeHashedEmail'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { requiredTrue, phoneValid, maxLength } from '/machinery/customValidation'

import { FormSuccess } from '/features/pageOnly/FormSuccess'
import { FormSection } from '/features/pageOnly/FormSection'
import { FormFieldRow, FormFieldText, FormFieldEmail, FormFieldTextarea, FormFieldCheckbox, FormFieldSelect } from '/features/buildingBlocks/FormField'
import { ButtonSubmit } from '/features/buildingBlocks/Button'
import { FormError } from '/features/buildingBlocks/FormError'
import { Link } from '/features/buildingBlocks/Link'

export function FormContact({ id, styleContext, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const firebaseApp = useFirebaseApp()
  const reportError = useReportError()
  const { businessHub } = useClientConfig()

  const isEcommerceCheckoutEventTrackedRef = React.useRef(false)

  const { mutate, isPending, isSuccess, error } = useMutation({
    mutationFn: handleSendRegistration,
    onError: e => {
      reportError(e)
    },
    onSuccess: handleSuccess
  })

  const { form: { fields }, submit } = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      emailAddress: '',
      phoneNumber: '',
      challengeType: '',
      question: '',
      privacyConsent: false,
    },
    fields: {
      firstName: required,
      lastName: required,
      companyName: optional,
      emailAddress: [required, email],
      phoneNumber: [optional, phoneValid],
      challengeType: required,
      question: [required, maxLength(5000)],
      privacyConsent: requiredTrue,
    },
    onSubmit: handleSubmit,
  })

  return (
    <>
      {isSuccess
        ? <FormSuccess title={__`contact-form-sent`} description={__`contact-form-sent-description`} {...{ id, styleContext, layoutClassName }} />
        : (
          <FormSection
            dataX='event-form'
            onSubmit={submit}
            onChange={handleFormChange}
            title={__`contact-us-now`}
            {...{ id, styleContext, layoutClassName }}
          >
            <FormFieldRow>
              <FormFieldText required label={__`form-label-firstname`} field={fields.firstName} />
              <FormFieldText required label={__`form-label-lastname`} field={fields.lastName} />
            </FormFieldRow>

            <FormFieldText label={__`form-label-company`} field={fields.companyName} />

            <FormFieldEmail required label={__`form-label-email`} field={fields.emailAddress} />

            <FormFieldText label={__`form-label-phone`} field={fields.phoneNumber} />

            <FormFieldSelect required label={__`form-label-challenge`} field={fields.challengeType} placeholder={__`form-label-challenge-placeholder`}
              options={[
                { value: 'Juridisch', label: 'Juridisch' },
                { value: 'Financieel', label: 'Financieel' },
                { value: 'Engineering', label: 'Engineering' },
                { value: 'Projectmanagement', label: 'Projectmanagement' },
                { value: 'Procesmanagement', label: 'Procesmanagement' },
                { value: 'Projectbeheersing', label: 'Projectbeheersing' },
                { value: 'Supply chain', label: 'Supply chain' },
                { value: 'Data & Analytics', label: 'Data & Analytics' },
                { value: 'IT', label: 'IT' }
              ]}
            />

            <FormFieldTextarea required label={__`form-label-question`} field={fields.question} />


            <FormFieldCheckbox required field={fields.privacyConsent}>
              <span>{ __`form-label-accept-the` }</span>
              <Link
                dataX='link-to-privacy-statement'
                href={`${businessHub.host}${businessHub.privacyStatement}`}
                label={__`form-label-privacy-statement`}
              />
            </FormFieldCheckbox>


            <ButtonSubmit dataX='submit-event-form' label={__`form-label-send`} {...{ isPending }} />

            {error && <FormError id='form-submit-error' errorMessage={__`form-submit-error`} />}
          </FormSection>
        )
      }
    </>
  )

  function handleFormChange(event) {
    if (isEcommerceCheckoutEventTrackedRef.current) return

    if (event.target.value?.length > 2) {
      pushToDataLayer({ event: 'form_start' })

      isEcommerceCheckoutEventTrackedRef.current = true
    }
  }

  function handleSubmit({ value, invalid }) {
    if (invalid) return

    storeHashedEmail(value.emailAddress)

    mutate({ formValues: value })
  }

  function handleSuccess() {
    pushToDataLayer({ event: 'form_submit' })
  }

  async function handleSendRegistration({ formValues }) {
    const { user: { uid } } = await signInAnonymously(getAuth(firebaseApp))
    const db = getDatabase(firebaseApp)
    const queueRef = ref(db, 'services/contact-form-processing-service/queue')
    const contactSubmission = {
      formSubmitDate: serverTimestamp(),
      formValues,
      uid,
      language,
    }

    await push(queueRef, contactSubmission)
  }
}
