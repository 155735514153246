import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import { FirebaseAppProvider } from '/machinery/FirebaseAppProvider'
import { useClientConfig } from '/machinery/ClientConfig'

import { FormContact } from './FormContact'

const queryClient = new QueryClient()

export default function FormContactApp({ id, styleContext }) {
  const config = useClientConfig()

  return (
    <FirebaseAppProvider config={config.firebase} name='form-contact'>
      <QueryClientProvider client={queryClient}>
        <FormContact {...{ id, styleContext }} />
      </QueryClientProvider>
    </FirebaseAppProvider>
  )
}
