import { isValidPhoneNumber } from 'libphonenumber-js/max'
import { error } from '@kaliber/forms/validation'

const linkedInUrlRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.+$/

export function requiredTrue(x) {
  const valid = typeof x === 'boolean' && x === true
  return !valid && error('requiredTrue')
}

export function maxFileSize(maxSize) {
  return x => x && (x.size > maxSize) && error('maxFileSize', maxSize)
}

export function minFileSize(minSize) {
  return x => x && (x.size <= minSize) && error('minFileSize')
}

export function fileExtension(extensions) {
  return x => {
    if (!x) return

    const [ext] = x.name.split('.').reverse()
    return (!ext || !extensions.some(x => ext.toLowerCase() === x)) &&
      error('fileExtension', extensions)
  }
}

export function checked(x) {
  return !x && error('checked')
}

export function whitespace(x) {
  return x && /\s/g.test(x) && error('whitespace')
}

export function phoneValid(phoneNumber) {
  return !isValidPhoneNumber(phoneNumber, 'NL') && error('number')
}

export function linkedInUrl(x) {
  return x && !linkedInUrlRegex.test(x) && error('linkedInUrl')
}

export function maxLength(length) {
  return x => (x.length >= length) && error('maxLength')
}
